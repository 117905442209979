body{
  margin: 0;
  padding: 0 0 0 0;
  font-family: sans-serif;
  background-image: linear-gradient(180deg, #eee, #fff 100px, #fff);
}

.navbar {
  margin-bottom: 30px;
}

.clickable {
  cursor: pointer;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
.container {
  max-width: 960px;
}

.pricing-header {
  max-width: 700px;
}
